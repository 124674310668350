.select {
  @apply relative inline-block text-13 leading-normal w-full min-h-[2rem];
}

.select.large {
  @apply !min-h-[2.5rem];
}
.select.large .select-selector {
  @apply !min-h-[2.5rem];
}
.select.large .select-selection-placeholder {
  @apply !top-2.5;
}
.select.large .select-arrow {
  @apply !h-[2.5rem];
}
.select.large .select-selection-item {
  @apply min-h-[1.875rem];
}
.select.large.select-single .select-selection-item {
  @apply leading-[1.875rem];
}

.select.border-danger > .select-selector {
  @apply !border-red-900;
}

.select-disabled,
.select-disabled input {
  cursor: not-allowed;
}

.select-disabled .select-selector {
  @apply !bg-separation-400;
}

.select .select-selection-placeholder {
  @apply text-black-400 pointer-events-none font-normal;
}

.select .select-selection-search-input {
  appearance: none;
}

.select .select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  appearance: none;
}
.select-single .select-selector {
  @apply relative flex items-center min-h-[2rem] bg-white;
}

.select-single .select-selector .select-selection-search {
  width: 100%;
}

.select-single .select-selector .select-selection-search-input {
  width: 100%;
}
.select-single .select-selector .select-selection-item {
  @apply overflow-hidden text-ellipsis w-[calc(100%_-_3rem)] block whitespace-nowrap;
}
.select-single .select-selector .select-selection-item,
.select-single .select-selector .select-selection-placeholder {
  @apply absolute pointer-events-none;
}

.select-single:not(.select-customize-input) .select-selector {
  transition: 0.2s linear;
  @apply py-1 pl-2.5 pr-8 border border-separation-900 rounded-lg hover:border-primary-900 bg-white hover:shadow-input focus:shadow-input focus:border-primary-900;
}

.select-single:not(.select-customize-input)
  .select-selector
  .select-selection-search-input {
  @apply border-0 outline-0 w-full;
}

.select-multiple .select-selector {
  transition: 0.2s linear;
  @apply flex py-1 pl-2.5 pr-8 border border-separation-900 rounded-lg hover:border-primary-900 min-h-[2rem] bg-white;
}

.select-multiple .select-selection-placeholder {
  @apply absolute top-1.5;
}

.select-multiple .select-selector .select-selection-item {
  @apply inline-flex h-full items-center  px-1.5 py-[1px] rounded-lg bg-separation-800;
}

.select-multiple .select-selection-item .select-selection-item-content {
  @apply mr-0 px-1;
}

.select-multiple .select-selection-item .select-selection-item-remove {
  @apply cursor-pointer text-primary-900 hidden;
}

.select-multiple .select-selector .select-selection-item-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.select-multiple .select-selector .select-selection-overflow {
  @apply flex flex-wrap w-full gap-x-1 gap-y-[4px] content-start;
}

.select-multiple .select-selector .select-selection-overflow-item {
  flex: none;
  max-width: 100%;
}

.select-multiple .select-selector .select-selection-overflow-item > span {
  @apply inline-flex h-full items-center rounded-lg py-[1px];
}

.select-multiple .select-selector .select-selection-overflow-item-suffix {
  @apply !relative !left-0;
}

.select-multiple .select-selector .select-selection-search {
  @apply relative max-w-full !w-[50px] h-full;
}

.select-multiple .select-selector .select-selection-search-mirror {
  @apply absolute z-[-999] hidden whitespace-nowrap top-0 left-0 invisible;
}

.select-multiple .select-selector .select-selection-search-input {
  @apply border-0 outline-0 min-w-[4.1px] max-w-full h-full;
}

.select-show-arrow.select-multiple .select-selector {
  @apply pr-8;
}

.select-show-arrow .select-arrow {
  @apply absolute top-0 right-0 w-8 h-8 pointer-events-none flex items-center justify-center;
}

.select-allow-clear .select-clear {
  @apply absolute top-1/2 right-[1.625rem] h-auto flex items-center cursor-pointer  -translate-y-1/2;
}

.select-allow-clear .select-clear svg {
  @apply p-[3px] bg-[#a7a7a7] text-white rounded-full w-3 h-3;
}

.select-focused .select-selector {
  @apply !border-primary-900;
}

.select-dropdown {
  @apply absolute rounded-lg py-1 px-2 border border-separation-900 bg-white z-select;
  box-shadow:
    0px 4px 3px rgba(0, 0, 0, 0.15),
    0px 1px 2px rgba(0, 0, 0, 0.25);
}

.select-dropdown-hidden {
  display: none;
}

.select-item {
  @apply p-2 rounded-lg text-sm flex justify-between gap-4;
}

.select-item-option-content {
  @apply flex-1 w-full flex items-center;
}

.select-item-option {
  position: relative;
}

.select-item-option-grouped {
  padding-left: 24px;
}

.select-item-option .select-item-option-state {
  @apply pointer-events-none w-3 h-3;
}

.select-item-option-active {
  transition: 0.2s linear;
  @apply bg-separation-400 cursor-pointer text-primary-900;
}

.select-item-option-disabled {
  color: #999;
}

.select-item-empty {
  text-align: center;
  color: #999;
}

.select-selection__choice-zoom {
  transition: all 0.3s;
}

.select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);
}

.select-selection__choice-zoom-appear.select-selection__choice-zoom-appear-active {
  opacity: 1;
  transform: scale(1);
}

.select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);
}

.select-selection__choice-zoom-leave.select-selection__choice-zoom-leave-active {
  opacity: 0;
  transform: scale(0.5);
}

.select-dropdown-slide-up-enter,
.select-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

.select-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

.select-dropdown-slide-up-enter.select-dropdown-slide-up-enter-active.select-dropdown-placement-bottomLeft,
.select-dropdown-slide-up-appear.select-dropdown-slide-up-appear-active.select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpIn;
  animation-play-state: running;
}

.select-dropdown-slide-up-leave.select-dropdown-slide-up-leave-active.select-dropdown-placement-bottomLeft {
  animation-name: rcSelectDropdownSlideUpOut;
  animation-play-state: running;
}

.select-dropdown-slide-up-enter.select-dropdown-slide-up-enter-active.select-dropdown-placement-topLeft,
.select-dropdown-slide-up-appear.select-dropdown-slide-up-appear-active.select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownIn;
  animation-play-state: running;
}

.select-dropdown-slide-up-leave.select-dropdown-slide-up-leave-active.select-dropdown-placement-topLeft {
  animation-name: rcSelectDropdownSlideDownOut;
  animation-play-state: running;
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

.rc-virtual-list-scrollbar {
  width: 5px !important;
}

.async-select-dropdown-loading::after {
  content: '';
  @apply absolute top-0 left-0 w-full h-full bg-white/50;
}
.select-tags.large .select-selector {
  @apply !min-h-[8.75rem];
}
.select-tags.inline .select-selector {
  @apply !min-h-0;
}
.select-tags .select-selector {
  @apply px-3 py-[0.625rem];
}
.select-tags .select-selector .select-selection-search {
  @apply !w-auto h-[2.125rem];
}
.select-tags .select-selector .select-selection-overflow-item {
  @apply h-auto;
}
.select-tags .select-selector .select-selection-overflow-item > span {
  @apply bg-separation-200 text-black-800 px-2 py-[0.375rem] rounded-lg border border-separation-900 h-auto;
  word-break: break-all;
}
.select-tags .select-selection-item .select-selection-item-remove {
  @apply block h-5;
}
.select-tags .select-selection-item-remove-icon {
  font-family: 'icon';
  text-indent: -9999px;
  font-size: 0;
}
.select-tags .select-selection-item-remove-icon:before {
  content: '\e90a';
  @apply text-black-400 relative text-[10px];
}
.select-tags .select-selector .select-selection-search-input {
  @apply text-black-800;
}

.select-tags.select-multiple .select-selection-placeholder {
  @apply !top-[1.25rem];
}
.select.select-error .select-selector {
  @apply border-red-900;
}

.selct-tags-quote .select-selector .select-selection-overflow-item > span {
  @apply p-0 border-none bg-transparent;
}
.selct-tags-quote .select-selector .select-selection-search {
  @apply h-10;
}

.select-search-inline:not(.select-customize-input) .select-selector {
  @apply border-none hover:shadow-none focus:shadow-none md:min-h-[3.375rem] min-h-[2.25rem];
}
.select-search-inline .select-selection-placeholder {
  @apply text-black-800;
}
.select-search-inline .select-arrow {
  @apply h-[2.25rem] md:h-[3.375rem];
}

.select-item.select-item-group {
  @apply pt-4;
}

.select-icon-type.select-single .select-selection-item {
  @apply flex items-center;
}
