@font-face {
  font-family: 'icon';
  src:
    url('../assets/fonts/icon.ttf?uaugep') format('truetype'),
    url('../assets/fonts/icon.woff?uaugep') format('woff'),
    url('../assets/fonts/icon.svg?uaugep#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='font-icon-'],
[class*=' font-icon-'] {
  font-family: 'icon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-icon-group:before {
  content: '\ea20';
}
.font-icon-cursor:before {
  content: '\ea1e';
}
.font-icon-opacity_box:before {
  content: '\ea1f';
}
.font-icon-cctv_var:before {
  content: '\ea1d';
}
.font-icon-folder:before {
  content: '\ea1c';
}
.font-icon-camera_mount:before {
  content: '\ea14';
}
.font-icon-dome:before {
  content: '\ea15';
}
.font-icon-extension_column:before {
  content: '\ea16';
}
.font-icon-house:before {
  content: '\ea17';
}
.font-icon-junction_boxes:before {
  content: '\ea18';
}
.font-icon-monitor_mouse:before {
  content: '\ea19';
}
.font-icon-mount_accessory:before {
  content: '\ea1a';
}
.font-icon-pendant_cap:before {
  content: '\ea1b';
}
.font-icon-customize:before {
  content: '\ea13';
}
.font-icon-export:before {
  content: '\ea12';
}
.font-icon-semi_circle:before {
  content: '\ea11';
}
.font-icon-question_mark:before {
  content: '\ea10';
}
.font-icon-drag_2:before {
  content: '\ea0f';
}
.font-icon-alarm_communicator:before {
  content: '\e9ee';
}
.font-icon-alarm_strobe:before {
  content: '\e994';
}
.font-icon-amplifier_1:before {
  content: '\e9cc';
}
.font-icon-amplifier_2:before {
  content: '\e9cd';
}
.font-icon-barcode:before {
  content: '\e9ce';
}
.font-icon-broadcast:before {
  content: '\e9cf';
}
.font-icon-cable_av:before {
  content: '\e9d0';
}
.font-icon-cable_optic:before {
  content: '\e9d1';
}
.font-icon-cctv_ep:before {
  content: '\e9d2';
}
.font-icon-cctv_fix:before {
  content: '\e9d3';
}
.font-icon-cctv_fy:before {
  content: '\e9d4';
}
.font-icon-cctv_mul:before {
  content: '\e9d5';
}
.font-icon-cctv_ptz:before {
  content: '\e9d6';
}
.font-icon-cctv_therminal:before {
  content: '\e9d7';
}
.font-icon-cctv_vf:before {
  content: '\e9d8';
}
.font-icon-cctv_x3l:before {
  content: '\e9d9';
}
.font-icon-chip_2:before {
  content: '\e9da';
}
.font-icon-chip_3:before {
  content: '\e9db';
}
.font-icon-co:before {
  content: '\e9dc';
}
.font-icon-door_handle:before {
  content: '\e9dd';
}
.font-icon-electric_box_2:before {
  content: '\e9de';
}
.font-icon-electric_box:before {
  content: '\e9df';
}
.font-icon-exit:before {
  content: '\e9e0';
}
.font-icon-fa_communicator:before {
  content: '\e9e1';
}
.font-icon-fa_pull_station:before {
  content: '\e9e2';
}
.font-icon-fa_relay:before {
  content: '\e9e3';
}
.font-icon-fa_strobe:before {
  content: '\e9e4';
}
.font-icon-fire_box:before {
  content: '\e9e5';
}
.font-icon-fire_control:before {
  content: '\e9e6';
}
.font-icon-fire_fighter_phone:before {
  content: '\e9e7';
}
.font-icon-fire_horn:before {
  content: '\e9e8';
}
.font-icon-gas_detector:before {
  content: '\e9e9';
}
.font-icon-gate:before {
  content: '\e9ea';
}
.font-icon-gun:before {
  content: '\e9eb';
}
.font-icon-light_beam:before {
  content: '\e9ec';
}
.font-icon-lighting_control:before {
  content: '\e9ed';
}
.font-icon-media_decoder:before {
  content: '\e9ef';
}
.font-icon-media_encoder:before {
  content: '\e9f0';
}
.font-icon-metal_detector:before {
  content: '\e9f1';
}
.font-icon-meter:before {
  content: '\e9f2';
}
.font-icon-monitor_temperature:before {
  content: '\e9f3';
}
.font-icon-online_meeting:before {
  content: '\e9f4';
}
.font-icon-overhead_door:before {
  content: '\e9f5';
}
.font-icon-panel:before {
  content: '\e9f6';
}
.font-icon-panic_button_2:before {
  content: '\e9f7';
}
.font-icon-power_outlet:before {
  content: '\e9f8';
}
.font-icon-pushbar_e:before {
  content: '\e9f9';
}
.font-icon-pushbar_m:before {
  content: '\e9fa';
}
.font-icon-scanner1:before {
  content: '\e9fb';
}
.font-icon-server_rtls:before {
  content: '\e9fc';
}
.font-icon-software_av:before {
  content: '\e9fd';
}
.font-icon-software_vms:before {
  content: '\e9fe';
}
.font-icon-software:before {
  content: '\e9ff';
}
.font-icon-sound_flat:before {
  content: '\ea00';
}
.font-icon-subwoofer:before {
  content: '\ea01';
}
.font-icon-switch_network:before {
  content: '\ea02';
}
.font-icon-switch_outlet:before {
  content: '\ea03';
}
.font-icon-switch:before {
  content: '\ea04';
}
.font-icon-tamper_switch_2:before {
  content: '\ea05';
}
.font-icon-tamper_switch:before {
  content: '\ea06';
}
.font-icon-touch_control:before {
  content: '\ea07';
}
.font-icon-turnstile1:before {
  content: '\ea08';
}
.font-icon-video_wall:before {
  content: '\ea09';
}
.font-icon-volume_control:before {
  content: '\ea0a';
}
.font-icon-walk:before {
  content: '\ea0b';
}
.font-icon-wireless_key_re:before {
  content: '\ea0c';
}
.font-icon-wireless_key_tr:before {
  content: '\ea0d';
}
.font-icon-workstation:before {
  content: '\ea0e';
}
.font-icon-circle:before {
  content: '\e9ca';
}
.font-icon-triangle:before {
  content: '\e9cb';
}
.font-icon-quote:before {
  content: '\e9c9';
}
.font-icon-bold:before {
  content: '\e9c3';
}
.font-icon-italic:before {
  content: '\e9c4';
}
.font-icon-list_bulleted:before {
  content: '\e9c5';
}
.font-icon-list_numbered:before {
  content: '\e9c6';
}
.font-icon-strike:before {
  content: '\e9c7';
}
.font-icon-underline:before {
  content: '\e9c8';
}
.font-icon-layout:before {
  content: '\e9c2';
}
.font-icon-heat:before {
  content: '\e9a8';
}
.font-icon-autodoor:before {
  content: '\e995';
}
.font-icon-badge:before {
  content: '\e996';
}
.font-icon-battery:before {
  content: '\e997';
}
.font-icon-bulb:before {
  content: '\e998';
}
.font-icon-cable_2:before {
  content: '\e999';
}
.font-icon-cctv_2:before {
  content: '\e99a';
}
.font-icon-cine_camera:before {
  content: '\e99b';
}
.font-icon-conference:before {
  content: '\e99c';
}
.font-icon-detect_location:before {
  content: '\e99d';
}
.font-icon-elevator:before {
  content: '\e99e';
}
.font-icon-emergency_call:before {
  content: '\e99f';
}
.font-icon-exitinguisher:before {
  content: '\e9a0';
}
.font-icon-finger_print:before {
  content: '\e9a1';
}
.font-icon-fire_hydran:before {
  content: '\e9a2';
}
.font-icon-firewall:before {
  content: '\e9a3';
}
.font-icon-first_aid:before {
  content: '\e9a4';
}
.font-icon-gas:before {
  content: '\e9a5';
}
.font-icon-heart:before {
  content: '\e9a6';
}
.font-icon-heat_humidity:before {
  content: '\e9a7';
}
.font-icon-humidity:before {
  content: '\e9a9';
}
.font-icon-key:before {
  content: '\e9aa';
}
.font-icon-kiosk:before {
  content: '\e9ab';
}
.font-icon-life_saver:before {
  content: '\e9ac';
}
.font-icon-light_switch:before {
  content: '\e9ad';
}
.font-icon-magnet:before {
  content: '\e9ae';
}
.font-icon-microphone:before {
  content: '\e9af';
}
.font-icon-motion:before {
  content: '\e9b0';
}
.font-icon-multimedia:before {
  content: '\e9b1';
}
.font-icon-music:before {
  content: '\e9b2';
}
.font-icon-panic_button:before {
  content: '\e9b3';
}
.font-icon-projector_screen:before {
  content: '\e9b4';
}
.font-icon-projector:before {
  content: '\e9b5';
}
.font-icon-rack:before {
  content: '\e9b6';
}
.font-icon-scanner:before {
  content: '\e9b7';
}
.font-icon-speaker:before {
  content: '\e9b8';
}
.font-icon-stage_light:before {
  content: '\e9b9';
}
.font-icon-thermostat:before {
  content: '\e9ba';
}
.font-icon-thief:before {
  content: '\e9bb';
}
.font-icon-turnstile:before {
  content: '\e9bc';
}
.font-icon-tv:before {
  content: '\e9bd';
}
.font-icon-video_distribution:before {
  content: '\e9be';
}
.font-icon-water_flow:before {
  content: '\e9bf';
}
.font-icon-wifi_2:before {
  content: '\e9c0';
}
.font-icon-window:before {
  content: '\e9c1';
}
.font-icon-align_center:before {
  content: '\e98f';
}
.font-icon-align_justify:before {
  content: '\e990';
}
.font-icon-align_left:before {
  content: '\e991';
}
.font-icon-align_right:before {
  content: '\e992';
}
.font-icon-letter:before {
  content: '\e993';
}
.font-icon-idf:before {
  content: '\e988';
}
.font-icon-iot:before {
  content: '\e989';
}
.font-icon-mdf:before {
  content: '\e98a';
}
.font-icon-nvr:before {
  content: '\e98b';
}
.font-icon-ups:before {
  content: '\e98c';
}
.font-icon-dvr:before {
  content: '\e987';
}
.font-icon-wifi:before {
  content: '\e98e';
}
.font-icon-videoplayer:before {
  content: '\e98d';
}
.font-icon-dropdown:before {
  content: '\e986';
}
.font-icon-unpin:before {
  content: '\e985';
}
.font-icon-loading:before {
  content: '\e984';
}
.font-icon-minus:before {
  content: '\e983';
}
.font-icon-happy_face:before {
  content: '\e981';
}
.font-icon-store:before {
  content: '\e982';
}
.font-icon-arrow_down:before {
  content: '\e901';
}
.font-icon-server_single:before {
  content: '\e97f';
}
.font-icon-share:before {
  content: '\e980';
}
.font-icon-more:before {
  content: '\e911';
}
.font-icon-mobile:before {
  content: '\e975';
}
.font-icon-access_cp:before {
  content: '\e95b';
}
.font-icon-angle:before {
  content: '\e95c';
}
.font-icon-book:before {
  content: '\e95d';
}
.font-icon-cable:before {
  content: '\e95e';
}
.font-icon-camera_swap:before {
  content: '\e95f';
}
.font-icon-camera:before {
  content: '\e960';
}
.font-icon-chat:before {
  content: '\e961';
}
.font-icon-chip:before {
  content: '\e962';
}
.font-icon-control_panel:before {
  content: '\e963';
}
.font-icon-decoder:before {
  content: '\e964';
}
.font-icon-download_square:before {
  content: '\e965';
}
.font-icon-drag:before {
  content: '\e966';
}
.font-icon-encoder:before {
  content: '\e967';
}
.font-icon-facedetect:before {
  content: '\e968';
}
.font-icon-glass:before {
  content: '\e969';
}
.font-icon-intercom_m:before {
  content: '\e96a';
}
.font-icon-intercom_s:before {
  content: '\e96b';
}
.font-icon-keypad:before {
  content: '\e96c';
}
.font-icon-knife:before {
  content: '\e96d';
}
.font-icon-modem:before {
  content: '\e96e';
}
.font-icon-monitor:before {
  content: '\e96f';
}
.font-icon-move:before {
  content: '\e970';
}
.font-icon-network_switch:before {
  content: '\e971';
}
.font-icon-opacity:before {
  content: '\e972';
}
.font-icon-path:before {
  content: '\e973';
}
.font-icon-pc:before {
  content: '\e974';
}
.font-icon-priority:before {
  content: '\e976';
}
.font-icon-process:before {
  content: '\e977';
}
.font-icon-scale:before {
  content: '\e978';
}
.font-icon-server_cloud:before {
  content: '\e979';
}
.font-icon-server_hyrid:before {
  content: '\e97a';
}
.font-icon-server_physical:before {
  content: '\e97b';
}
.font-icon-switch_2:before {
  content: '\e97c';
}
.font-icon-switch_3:before {
  content: '\e97d';
}
.font-icon-unlink:before {
  content: '\e97e';
}
.font-icon-box:before {
  content: '\e956';
}
.font-icon-cart:before {
  content: '\e957';
}
.font-icon-chart:before {
  content: '\e958';
}
.font-icon-map:before {
  content: '\e959';
}
.font-icon-moneybag:before {
  content: '\e95a';
}
.font-icon-shield:before {
  content: '\e955';
}
.font-icon-parent_account:before {
  content: '\e954';
}
.font-icon-alarm:before {
  content: '\e94a';
}
.font-icon-cctv:before {
  content: '\e94b';
}
.font-icon-door:before {
  content: '\e94c';
}
.font-icon-fire:before {
  content: '\e94d';
}
.font-icon-intercom:before {
  content: '\e94e';
}
.font-icon-new_tab:before {
  content: '\e94f';
}
.font-icon-other_device:before {
  content: '\e950';
}
.font-icon-phone_call:before {
  content: '\e951';
}
.font-icon-pin:before {
  content: '\e952';
}
.font-icon-text:before {
  content: '\e953';
}
.font-icon-building:before {
  content: '\e949';
}
.font-icon-calculate:before {
  content: '\e941';
}
.font-icon-device:before {
  content: '\e942';
}
.font-icon-document:before {
  content: '\e943';
}
.font-icon-flag:before {
  content: '\e944';
}
.font-icon-rocket:before {
  content: '\e945';
}
.font-icon-setting:before {
  content: '\e946';
}
.font-icon-sparkle:before {
  content: '\e947';
}
.font-icon-ticket:before {
  content: '\e948';
}
.font-icon-print:before {
  content: '\e940';
}
.font-icon-spec:before {
  content: '\e93f';
}
.font-icon-arrow_tail:before {
  content: '\e93e';
}
.font-icon-order:before {
  content: '\e93c';
}
.font-icon-archive:before {
  content: '\e93d';
}
.font-icon-convert:before {
  content: '\e93b';
}
.font-icon-label:before {
  content: '\e93a';
}
.font-icon-bank:before {
  content: '\e939';
}
.font-icon-info:before {
  content: '\e938';
}
.font-icon-remove:before {
  content: '\e937';
}
.font-icon-attachment:before {
  content: '\e927';
}
.font-icon-file:before {
  content: '\e928';
}
.font-icon-fullscreen:before {
  content: '\e929';
}
.font-icon-image:before {
  content: '\e92a';
}
.font-icon-lift:before {
  content: '\e92b';
}
.font-icon-link:before {
  content: '\e92c';
}
.font-icon-lock:before {
  content: '\e92d';
}
.font-icon-minimize:before {
  content: '\e92e';
}
.font-icon-miniplayer:before {
  content: '\e92f';
}
.font-icon-pause:before {
  content: '\e930';
}
.font-icon-ruler:before {
  content: '\e931';
}
.font-icon-speaker_max:before {
  content: '\e932';
}
.font-icon-speaker_medium:before {
  content: '\e933';
}
.font-icon-speaker_mute:before {
  content: '\e934';
}
.font-icon-star_filled:before {
  content: '\e935';
}
.font-icon-star_stretch:before {
  content: '\e936';
}
.font-icon-global:before {
  content: '\e926';
}
.font-icon-protected:before {
  content: '\e923';
}
.font-icon-unprotect:before {
  content: '\e924';
}
.font-icon-download:before {
  content: '\e922';
}
.font-icon-clock:before {
  content: '\e921';
}
.font-icon-sort_down:before {
  content: '\e909';
}
.font-icon-sort_up:before {
  content: '\e915';
}
.font-icon-trash:before {
  content: '\e908';
}
.font-icon-calendar:before {
  content: '\e90f';
}
.font-icon-add:before {
  content: '\e900';
}
.font-icon-arrow_left:before {
  content: '\e902';
}
.font-icon-arrow_right:before {
  content: '\e903';
}
.font-icon-arrow_up:before {
  content: '\e904';
}
.font-icon-block:before {
  content: '\e905';
}
.font-icon-card:before {
  content: '\e906';
}
.font-icon-check:before {
  content: '\e907';
}
.font-icon-close:before {
  content: '\e90a';
}
.font-icon-copy:before {
  content: '\e90b';
}
.font-icon-dob:before {
  content: '\e90c';
}
.font-icon-edit:before {
  content: '\e90d';
}
.font-icon-email:before {
  content: '\e90e';
}
.font-icon-filter:before {
  content: '\e910';
}
.font-icon-location:before {
  content: '\e912';
}
.font-icon-menu:before {
  content: '\e913';
}
.font-icon-money:before {
  content: '\e914';
}
.font-icon-notification:before {
  content: '\e916';
}
.font-icon-phone:before {
  content: '\e917';
}
.font-icon-power:before {
  content: '\e918';
}
.font-icon-refresh:before {
  content: '\e919';
}
.font-icon-search:before {
  content: '\e91a';
}
.font-icon-send:before {
  content: '\e91b';
}
.font-icon-star:before {
  content: '\e91c';
}
.font-icon-unlock:before {
  content: '\e91d';
}
.font-icon-upload:before {
  content: '\e91e';
}
.font-icon-user:before {
  content: '\e91f';
}
.font-icon-view:before {
  content: '\e920';
}
.font-icon-wrench:before {
  content: '\e925';
}
.font-icon-lead:before {
  content: '\ea21';
}
.font-icon-whatsapp:before {
  content: '\ea93';
}
